import style from "./Header.module.css";
import finexp_logo from "../../assets/finexp_logo.svg";
//import { Link } from "react-scroll";
import menu_icon from "../../assets/menu_icon.svg";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../languageSelector/LanguageSelector";
import { Link, useMatch } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { whitelists } from "../../i18n";



const Header = (props) => {

  setTimeout(function () {
    window.BinotelCallTracking[512006].replacePhoneNumbersOnDynamicContent();
  }, 500);

  const { t, i18n } = useTranslation();
  const match = useMatch('/:lang/*');
  let lang = match && match.params ?
    (whitelists.includes(match.params.lang) ? match.params.lang : null) :
    null;

  const homeLink = lang ? `/${lang}` : '/';

  const navData = [
    {
      id: 'about-block',
      description: props.t("about_us_link")
    },
    {
      id: 'offer-block',
      description: props.t("offer_link")
    },
    {
      id: 'price-block',
      description: props.t("price_link")
    },
    {
      id: 'benefit-block',
      description: props.t("benefits_link")
    },
    {
      id: 'contact-block',
      description: props.t("contact_link")
    },
  ];

  const navItem = navData.map((item) => (
    <div key={item.id} className={style["nav-item"]}><HashLink to={`${lang ? `/${lang}/#${item.id}` : `/#${item.id}`}`} smooth>{item.description}</HashLink></div>
  ));
  return (
    <header className={style["header"]}>
      <div className={style["content"]}>
        <Link to={homeLink}><img className={style["logo"]} src={finexp_logo} alt="" /></Link>
        <div className={style["link-block"]}>
          {navItem}
          <Link
            className={style["link-block__item"]}
            to="blog"
          >
            {props.t("blog_link")}
          </Link>
        </div>
        <div className={style["contact-block"]}>
          <LanguageSelector onLanguageChange={(lange) => props.changeLanguages(lange)} />
          <a
            className="binct-phone-number-1"
            href="tel:+48718808807"
          >
            +48 718 808 807
          </a>
        </div>
        <button
          className={style["menu-btn"]}
          onClick={props.setMobileMenuState}
        >
          <img src={menu_icon} alt="" />
        </button>
      </div>
    </header>
  );
};

export default Header;
