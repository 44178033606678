import style from "./MobileMenu.module.css";
import logo from "../../assets/finexp_logo.svg";
import close_icon from "../../assets/close_icon.svg";
import logoInst from '../../assets/logoInst.svg';
import logoFb from '../../assets/logoFb.svg';
import logoLkdn from '../../assets/logoLkdn.svg';
import { Link, useMatch } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { whitelists } from "../../i18n";
import { useTranslation } from "react-i18next";

import LanguageSelector from "../languageSelector/LanguageSelector";

const links = [
  {
    img: logoInst,
    link: 'https://www.instagram.com/finance.expert.pl/'
  },
  {
    img: logoFb,
    link: 'https://www.facebook.com/financeexpert.polska'
  },
  {
    img: logoLkdn,
    link: 'https://www.linkedin.com/company/financeexpertpl/'
  },

];

const linkItem = links.map((item) => (
  <div key={item.link} className={style["social-link-item-content"]} >
    <div className={style["social-img"]} >
      <a href={item.link} target="blank">
        <img src={item.img} alt="" />
      </a>
    </div>
  </div>
))
const MobileMenu = (props) => {

  setTimeout(function () {
    window.BinotelCallTracking[512006].replacePhoneNumbersOnDynamicContent();
  }, 500);

  const { t, i18n } = useTranslation();
  const match = useMatch('/:lang/*');
  let lang = match && match.params ?
    (whitelists.includes(match.params.lang) ? match.params.lang : null) :
    null;

  const homeLink = lang ? `/${lang}` : '/';

  const navData = [
    {
      id: 'about-block',
      description: props.t("about_us_link")
    },
    {
      id: 'offer-block',
      description: props.t("offer_link")
    },
    {
      id: 'price-block',
      description: props.t("price_link")
    },
    {
      id: 'benefit-block',
      description: props.t("benefits_link")
    },
    {
      id: 'contact-block',
      description: props.t("contact_link")
    },
  ];

  const navItem = navData.map((item) => (
    <div key={item.id} className={style["nav-item"]} onClick={props.setMobileMenuState}><HashLink to={`${lang ? `/${lang}/#${item.id}` : `/#${item.id}`}`} smooth>{item.description}</HashLink></div>
  ));

  return (
    <div className={style["mobile-menu"]}>
      <div className={style["head-block"]}>
        <Link to={homeLink} onClick={props.setMobileMenuState}>
          <img className={style["head-block__logo"]} src={logo} alt="" />
        </Link>

        <button onClick={props.setMobileMenuState}>
          <img src={close_icon} alt="" />
        </button>
      </div>
      <div className={style["lang-block"]}>

        <LanguageSelector width={'210px'} onLanguageChange={(lange) => props.changeLanguages(lange)} />

      </div>
      <div className={style["link-block"]}>
        {navItem}
        <div onClick={props.setMobileMenuState}>
          <Link
            className={style["link-block__item"]}
            to="blog"

          >
            {props.t("blog_link")}
          </Link>
        </div>

      </div>
      <div className={style["contact-block"]}>
        <button onClick={props.setMobileMenuState}>
          <a className={style["link"]} href="mailto:biuro@finexp.pl">
            biuro@finexp.pl
          </a>
        </button>
        <button onClick={props.setMobileMenuState}>
          <a className="binct-phone-number-1" href="tel:+48 502 708 529">
            +48 502 708 529
          </a>
        </button>
      </div>
      <div className={style["link-wrapper"]} >
        {linkItem}
      </div>
    </div>
  );
};

export default MobileMenu;
